
//
// Helpers
//

function log () {
  console.log.apply(console, arguments);
  return arguments[0];
}

function toArray (xs) {
  return [].slice.apply(xs);
}

function selectDom (sel, parent) {
  if (!parent) parent = document;
  return toArray(parent.querySelectorAll(sel));
}

function swallow (λ) {
  return function _event (event) {
    event.preventDefault();
    λ(event);
    return false;
  };
}

