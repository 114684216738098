
//
// Dom Ready
//

document.addEventListener('DOMContentLoaded', function () {

  var switchTriggers = selectDom('[data-switch]')

  var f = new Flickity(document.querySelector('.slideshow'), {
    autoPlay: 3000,
    pageDots: false,
    wrapAround: true,
    prevNextButtons: false,
    imagesLoaded: true
  });

  function trim (s) {
    return s.toLowerCase().replace(/[^\w]+/g, '');
  }

  function reveal () {
    document.querySelector('body').classList.toggle('reveal');

    switchTriggers.forEach(function (trigger) {
      //if (trim(trigger.textContent) === 'contact') trigger.textContent = 'Home';
      if (trim(trigger.textContent) === 'profile') trigger.textContent = 'Home';
      if (trim(trigger.textContent) === 'home') trigger.textContent = 'Profile';
    });
  }

  switchTriggers.forEach(function (trigger) {
    trigger.addEventListener('click', reveal);
    trigger.addEventListener('touchstart', swallow(reveal));
  });

  f.on('staticClick', function (event) {
    if (event.pageX < window.innerWidth/2) {
      f.previous();
    } else {
      f.next();
    }
  });

  setTimeout(f.resize.bind(f), 100);
  setTimeout(f.resize.bind(f), 200);
  setTimeout(f.resize.bind(f), 500);
  setTimeout(f.resize.bind(f), 1000);

  document.querySelector('[data-switch]').addEventListener('click', reveal);
  window.addEventListener('orientationchange', f.resize.bind(f));

});

